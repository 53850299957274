import React            from 'react'
import Helmet           from 'react-helmet'
import { Link }         from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import config           from '../../data/site-config'
import testimonials     from '../../data/testimonials-data.json'

import Layout           from '../components/layout'
import MainWrapper      from '../components/MainWrapper/MainWrapper'
import PageHero         from '../components/PageHero'
import TestimonialCard  from '../components/TestimonialCard/TestimonialCard'

class TestimonialsPage extends React.Component {
  render() {
    return (
      <Layout>

        <Helmet
          title={`🌟 Best Web Designers in Dallas Texas • Spacetime Web Design`}
          meta={[
            { name: 'description', content: 'Spacetime is highly rated as one of the best web designers in Dallas. Clients seek us to build fantastic experiences through web design and technology' }
          ]}
          link={[
            { rel: 'canonical', href: `${config.siteUrl}/testimonials/` }
          ]}
        />
        <Helmet>
          <script className='structured-data-list' type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "LocalBusiness",
                "name": "Spacetime",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "5830 Granite Pkwy #100-243",
                  "addressLocality": "Plano",
                  "addressRegion": "TX",
                  "postalCode": "75024"
                },
                "telePhone": "469-270-1500",
                "openingHours": "Mo,Tu,We,Th,Fr 9:00-17:00",
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": "33.0875525",
                  "longitude": "-96.8211156"
                },
                "url": "https://www.heyspacetime.com/",
                "logo": "https://www.heyspacetime.com/misc/spacetime-logo.png",
                "image": "https://www.heyspacetime.com/misc/spacetime-social-cover.png",
                "priceRange":"$$",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "5",
                  "ratingCount": "11"
                }
              }
            `}
          </script>
        </Helmet>

        <PageHero>
          <h3 className="t-headline">Much ado about Spacetime&mdash;<span className="o-60">and our clients have <em>much</em> to say</span></h3>
        </PageHero>

        <MainWrapper>
          <section className="pv6 bg-fog-white">
            <div className="row flex justify-center flex-column items-center">
              <h1 className="f2 fw4 lh-copy tc mt0">“One of the best web designers in Dallas…”</h1>
              <span className="f3 db mv2 tracked">★★★★★</span>
              <span className="fw6 f6">5 of 5 stars</span>

              <OutboundLink href="http://bit.ly/31DCPAq" target="_blank" rel="noopener" className="bg-white mt4 pv3 ph4 br3 shadow-3 hover-shadow-4">
                <img src="/misc/google-my-business-review-ratings.png" alt="Google My Business logo" className="mw4" />
              </OutboundLink>
            </div>
          </section>
          <div className="row pv6 flex-l">
            <div className="mw6-m center-m w-50-l">
              {testimonials.testimonials.slice(0, 4).map(e => (
                <TestimonialCard
                  class="mh2 mb3"
                  key={e.name}
                  cover={e.cover}
                  logo={e.logo}
                  quote={e.quote}
                  name={e.name}
                  role={e.role}
                  business={e.business}
                  path={e.path}
                  avatar={e.avatar}
                  color={e.color}
                />
              ))}
            </div>
            <div className="mw6-m center-m w-50-l">
              {testimonials.testimonials.slice(4, 10).map(e => (
                <TestimonialCard
                  class="mh2 mb3"
                  key={e.name}
                  cover={e.cover}
                  logo={e.logo}
                  quote={e.quote}
                  name={e.name}
                  role={e.role}
                  business={e.business}
                  path={e.path}
                  avatar={e.avatar}
                  color={e.color}
                />
              ))}
            </div>
          </div>

          <section className="row ph3 pv4 pv5-ns mv5 bg-washed-yellow tc">
              <h2 className="f2">Ready to work with the best web designers in Dallas?</h2>
              <Link
                to="/contact/"
                className="ttu f7 fw8 tc link br1 ph5 pv2 pv3-ns dib mv4 dim white br-pill bg-gradient-pink">
                  Contact us →
              </Link>
          </section>

        </MainWrapper>
      </Layout>
    );
  }
}

export default TestimonialsPage
