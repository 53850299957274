import React   from 'react'
import { Link }         from 'gatsby'

const TestimonialCard = (props) => (
  <div className={`TestimonialCard deep-shadow bg-white pb2 ${props.class}`}>
    {props.cover && (
      <img className="TestimonialCard__feature" src={props.cover} alt={`${props.name} cover`} />
    )}
    <p className="TestimonialCard__quote f6 ph3 ph4-ns pt3 ma0">{props.quote}</p>
    <footer className="flex ph3 ph4-ns pv3">
      {props.avatar && (
        <img className="TestimonialCard__avatar br-100 w3 h3 mr3" src={props.avatar} alt={props.name} />
      )}
      <h5 className="TestimonialCard__name mv2 items-center">
        {props.name}, {props.business}
        <span className="TestimonialCard__rating db mt2 tracked">★★★★★</span>
      </h5>
    </footer>
    {props.path && (
      <Link
        to={props.path}
        className="f6 fw6 tc link br1 ph3 pv2 pv3-ns db mh2 dim"
        style={{backgroundColor: "#e3effd", color: "#036EF4"}}>
          Read the Case Study →
      </Link>
    )}
  </div>
)

export default TestimonialCard
